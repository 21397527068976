<template>
  <div>
    <p class="title">Login</p>
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <form @submit.prevent="onSubmit">
        <ValidationProvider name="email" rules="required" v-slot="{ errors }">
          <div class="field mb-1">
            <input
              v-model="user.email"
              class="input"
              v-bind:class="{ 'is-danger': errors[0] }"
              type="text"
              placeholder="Email"
              autocomplete="username"
            />
          </div>
        </ValidationProvider>
        <ValidationProvider
          name="password"
          rules="required"
          v-slot="{ errors }"
        >
          <div class="field">
            <input
              v-model="user.password"
              class="input"
              v-bind:class="{ 'is-danger': errors[0] }"
              type="password"
              placeholder="Senha"
              autocomplete="current-password"
            />
          </div>
        </ValidationProvider>
        <hr />

        <div class="level">
          <button :disabled="invalid" class="button is-info" type="submit">
            Login
          </button>
          <router-link to="/recover_password" class="is-size-7 is-pulled-right"
            >Esqueci minha senha!</router-link
          >
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
//import { user } from "../../services/user";

extend("required", {
  ...required,
  message: "Esse campo é requerido",
});
extend("email", {
  ...email,
  message: "Email inválido",
});

export default {
  name: "ModalAuth",
  props: ["openAuth"],
  data() {
    return {
      user: {},
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        this.$http
          .post(`${process.env.VUE_APP_URI}auth`, this.user)
          .then((response) => {
            this.$store.commit("setUser", response.data);
            this.$router.push("/doctor");
          })
          .catch(() => {
            this.$store.commit("setError", true);
            setTimeout(() => this.$store.commit("setError"), 800);
          });
      });
    },
  },
  created() {
    this.$store.commit("setToken");
  },
};
</script>